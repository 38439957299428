@charset 'UTF-8';

html.is-gnav-open {
    body {
        position: fixed;
    }
}

.header {
    position: static;
    z-index: 9999;
    padding-top: 117px;

    @include SP {
        padding-top: 79px;
    }

    &.header-fixed {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;

        .navigation {
            opacity: 0;
            visibility: hidden;
        }
    }

    .inHeader {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(flex-start);
        margin-bottom: 10px;
        transition: all 0.3s ease;
        position: absolute;
        background: $white;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;

        @include SP {
            margin-bottom: 0;
        }
    }

    .inHeader-left {
        padding: 20px 32px;
        box-sizing: border-box;

        @include max-screen(900px) {
            padding: 15px 20px;
        }

        @include SP {
            padding: 24px 8px 16px 10px;
        }

        #logo {
            @include flexbox;

            a {
                display: block;
                padding-right: 20px;
                margin-right: 24px;
                border-right: 1px solid $border;

                @include HOVER {
                    &:hover {
                        opacity: 0.65;
                    }
                }

                @include max-screen(1024px) {
                    padding-right: 10px;
                    margin-right: 10px;
                }

                @include SP {
                    margin-right: 0;
                    padding-right: 0;
                    border: none;
                }

                img {
                    @include max-screen(1024px) {
                        width: 172px;
                    }

                    @include ipad {
                        width: 242px;
                    }

                    @include SP {
                        width: 146px;
                    }

                    @include IP5 {
                        width: 100px;
                    }
                }
            }

            .txt-logo {
                @include font(13, 24, 20);
                font-weight: 500;
                padding-top: 10px;
                white-space: nowrap;

                @include max-screen(1024px) {
                    @include font-size(10);
                    padding-top: 5px;
                }

                @include ipad {
                    padding-top: 14px;
                }

                span {
                    margin-bottom: 2px;
                    display: block;
                }
            }
        }
    }

    .inHeader-right {
        max-width: 660px;
        width: 100%;
        @include flexbox;
        @include flex-wrap(wrap);
        transition: all 0.3s ease;

        @include max-screen(1024px) {
            width: 55%;
            max-width: 100%;
        }

        @include SP {
            width: calc(100% - 164px);
        }

        @include IP5 {
            width: calc(100% - 118px);
        }

        .sub-navi {
            width: calc(100% - 75px);
            background: $orange02;
            padding: 9px 12px 15px;

            @include max-screen(900px) {
                width: calc(100% - 63px);
            }

            @include SP {
                width: calc(100% - 58px);
                padding: 0;
            }

            &__txt {
                text-align: center;
                color: $white;
                @include font(12, 16, 100, bold);
                margin-bottom: 8px;

                @include SP {
                    @include font(11, 16, 70, bold);
                    margin-bottom: 0;
                    padding: 2px 0;
                    border-right: 1px solid $white;
                }

                span {
                    display: inline-block;
                    padding: 0 50px;
                    position: relative;

                    @include SP {
                        padding: 0;
                    }

                    &:before,
                    &:after {
                        content: "";
                        width: 36px;
                        height: 1px;
                        background: $white;
                        position: absolute;
                        top: 50%;
                        @include transform(translateY(-50%));

                        @include SP {
                            display: none;
                        }
                    }

                    &:before {
                        left: 0;
                    }

                    &:after {
                        right: 0;
                    }
                }
            }

            &.is-hide {
                opacity: 0;
                visibility: hidden;
            }
        }

        .button-menu {
            width: 75px;
            padding: 30px 20px 0;
            box-sizing: border-box;
            position: relative;
            @include font(9, 12, 100, 600);
            @include ffJose;
            white-space: nowrap;
            transition: all 0.3s ease;
            cursor: pointer;
            color: $orange02;

            @include HOVER {
                &:hover {
                    background: $color_body;
                    color: $white;

                    .hamberger {
                        span {
                            background: $white;
                        }
                    }
                }
            }

            @include max-screen(900px) {
                width: 63px;
                padding: 19px 15px 0 15px;
            }

            @include SP {
                width: 58px;
                padding: 22px 12px 0;
                background: $orange02;
                color: $white;
            }

            &.is-active {
                background: $color_body;
                padding: 35px 20px 0;

                @include SP {
                    padding: 30px 12px 0;
                }

                .menu-on {
                    display: none;
                }

                .hamberger {
                    span {
                        position: absolute;
                        background: $white;
                        top: 50%;
                        transition: all 0.5s;
                        -webkit-transition: all 0.5s;
                        -moz-transition: all 0.5s;
                        -ms-transition: all 0.5s;
                        -o-transition: all 0.5s;

                        &:nth-child(1) {
                            @include transform(translateY(-50%) rotate(138deg));
                        }

                        &:nth-child(2) {
                            @include transform(translateY(-50%) rotate(-138deg));
                        }
                    }
                }
            }

            .hamberger {
                position: relative;
                width: 32px;
                height: 13px;
                margin: 0 0 11px;
                line-height: 1;
                color: $orange02;

                @include SP {
                    margin-bottom: 11px;
                }

                span {
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: $orange;
                    margin-bottom: 10px;

                    @include SP {
                        background: $white;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .navigation {
        margin-bottom: 5px;

        @include SP {
            display: none;
        }

        .nav-navbar {
            max-width: 1000px;
            margin: 0 auto;
            @include flexbox;
            @include flex-wrap(wrap);
            @include justify-content(space-between);

            @include ipad {
                padding: 0 50px;
            }

            @include max-screen(1000px) {
                max-width: 100%;
                padding: 0 22px;
            }

            a {
                display: block;
                height: 100%;
                @include font(15, 20, 0);
                font-weight: 400;
                text-align: center;
                position: relative;
                padding-bottom: 5px;
                transition: all 0.3s ease;
                color: $color_body;

                @include max-screen(900px) {
                    @include font-size(13);
                }

                &:after {
                    content: "";
                    width: 0;
                    height: 2px;
                    background: $orange;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 100%;
                    transition-property: width;
                    transition-duration: 0.3s;
                    transition-timing-function: ease-out;
                }

                @include HOVER {
                    &:hover {
                        opacity: 1;

                        &:after {
                            width: 100%;
                            left: 0;
                            right: auto;
                        }
                    }
                }

                &.current {
                    &:after {
                        width: 100%;
                        left: 0;
                        right: auto;
                    }
                }

                &.no-active {
                    opacity: 0.4;
                    pointer-events: none;
                }
            }
        }
    }
}

.subnav-inner {
    @include flexbox;

    .sub-navi {
        &__link {
            flex: 1;
            max-width: 180px;
            margin-right: 7px;
            background: $white;

            @include SP {
                margin-right: 0;
                border-right: 1px solid $orange02;
            }

            .link-ico {
                display: none;

                @include SP {
                    display: block;
                    margin-bottom: 6px;
                }
            }

            .link-txt {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    left: -5px;
                    top: 60%;
                    @include transform(translateY(-50%));

                    @include SP {
                        display: none;
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: -5px;
                    top: 60%;
                    @include transform(translateY(-50%));
                    z-index: 2;
                    display: none;
                    transition: all 0.3s ease;

                    @include SP {
                        display: none;
                    }
                }
            }

            .no-active {
                opacity: 0.4;
                pointer-events: none;
            }
        }

        &__link01 {
            @include SP {
                border-left: 1px solid $orange02;
            }

            .link-txt {
                padding-left: 25px;

                @include max-screen(900px) {
                    padding-left: 20px;
                }

                @include SP {
                    padding: 0;
                }

                &:after {
                    width: 22px;
                    height: 25px;
                    background: url($img_url + "img/common/icon/ico_blog.svg") no-repeat center;
                    background-size: 22px 25px;
                    left: 0;

                    @include max-screen(900px) {
                        width: 15px;
                        height: 18px;
                        background-size: 15px 18px;
                    }
                }

                &:before {
                    width: 22px;
                    height: 25px;
                    background: url($img_url + "img/common/icon/ico_blog_over.svg") no-repeat center;
                    background-size: 22px 25px;
                    left: 0;

                    @include max-screen(900px) {
                        width: 15px;
                        height: 18px;
                        background-size: 15px 18px;
                    }
                }
            }
        }

        &__link02 {
            .link-txt {
                padding-left: 22px;

                @include max-screen(900px) {
                    padding-left: 15px;
                }

                @include SP {
                    padding-left: 0;
                }

                &:after {
                    width: 17px;
                    height: 19px;
                    background: url($img_url + "img/common/icon/ico_download.svg") no-repeat center;
                    background-size: 17px 19px;

                    @include max-screen(900px) {
                        width: 14px;
                        height: 16px;
                        background-size: 14px 16px;
                    }
                }

                &:before {
                    width: 17px;
                    height: 19px;
                    background: url($img_url + "img/common/icon/ico_download_over.svg") no-repeat center;
                    background-size: 17px 19px;

                    @include max-screen(900px) {
                        width: 14px;
                        height: 16px;
                        background-size: 14px 16px;
                    }
                }
            }

            &.no-active {
                background: $white;

                a {
                    opacity: 0.4;
                    pointer-events: none;
                }
            }
        }

        &__link03 {
            margin-right: 0;
            max-width: 182px;

            @include SP {
                border-right: 1px solid $white;
            }

            .link-txt {
                padding-left: 25px;

                @include max-screen(900px) {
                    padding-left: 15px;
                }

                @include SP {
                    padding-left: 0;
                }

                &:after {
                    width: 25px;
                    height: 23px;
                    background: url($img_url + "img/common/icon/ico_seminar.svg") no-repeat center;
                    background-size: 25px 23px;
                    left: -9px;

                    @include max-screen(900px) {
                        width: 18px;
                        height: 16px;
                        background-size: 18px 16px;
                    }
                }

                &:before {
                    width: 25px;
                    height: 23px;
                    background: url($img_url + "img/common/icon/ico_seminar_over.svg") no-repeat center;
                    background-size: 25px 23px;
                    left: -9px;

                    @include max-screen(900px) {
                        width: 18px;
                        height: 16px;
                        background-size: 18px 16px;
                    }
                }
            }
        }
    }

    a {
        display: block;
        height: 100%;
        text-align: center;
        color: $orange02;
        background: $white;
        @include font(15, 16, 0, bold);
        padding: 12px 0;

        @include HOVER {
            &:hover {
                opacity: 1;
                background: $color_body;
                color: $white;

                .link-txt {
                    &:before {
                        display: block;
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }

        @include max-screen(1024px) {
            @include font-size(12);
        }

        @include max-screen(900px) {
            @include font-size(10);
        }

        @include SP {
            background: $white;
            color: $orange02;
            @include font-size(11);
            padding: 10px 0 8px;
        }
    }
}

.layMenu {
    background: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9998;
    display: none;

    @include SP {
        min-height: 0;
    }

    &.is-open {
        display: block;
    }

    .no-active {
        pointer-events: none;
        opacity: 0.4;
    }

    &__content {
        width: 100%;
        height: 100vh;
        padding: 107px 0 80px;
        overflow-y: scroll;

        @include SP {
            padding: 78px 0;
        }
    }

    .m-navi {
        background: $orange02;
        padding: 42px 0 38px;

        @include SP {
            padding: 29px 12px;
        }

        &__txt {
            margin-bottom: 34px;
            text-align: center;
            @include font(22, 27, 100, bold);
            color: $white;

            @include SP {
                @include font(18, 23);
                margin-bottom: 15px;
            }

            span {
                position: relative;
                display: inline-block;
                padding: 0 86px;

                @include SP {
                    padding: 0 45px;
                }

                &:before,
                &:after {
                    content: "";
                    width: 65px;
                    height: 2px;
                    background: $white;
                    position: absolute;
                    top: 50%;
                    @include transform(translateY(-50%));

                    @include SP {
                        width: 34px;
                    }
                }

                &:before {
                    left: 0;
                }

                &:after {
                    right: 0;
                }
            }
        }

        .sub-navi {
            &__link {
                max-width: 322px;
                margin-right: 15px;

                &:last-of-type {
                    margin-right: 0;
                }

                @include SP {
                    border: none;
                    max-width: 100%;
                    width: calc(33.333% - 5.33px);
                    margin-right: 8px;
                    border-radius: 4px;
                }

                .link-ico {
                    @include SP {
                        margin-bottom: 16px;
                    }
                }
            }

            &__link01 {
                .link-txt {
                    padding-left: 35px;

                    @include SP {
                        padding-left: 0;
                    }
                }

                .link-txt:after {
                    width: 30px;
                    height: 34px;
                    background-size: 30px 34px;
                }

                .link-txt:before {
                    width: 30px;
                    height: 34px;
                    background-size: 30px 34px;
                }
            }

            &__link02 {
                .link-txt {
                    padding-left: 35px;

                    @include SP {
                        padding-left: 0;
                    }
                }

                .link-txt:after {
                    width: 24px;
                    height: 27px;
                    background-size: 24px 27px;
                    left: 0;
                    top: 55%;
                }

                .link-txt:before {
                    width: 24px;
                    height: 27px;
                    background-size: 24px 27px;
                    left: 0;
                    top: 55%;
                }
            }

            &__link03 {
                .link-ico {
                    @include SP {
                        margin-bottom: 10px;
                    }
                }

                .link-txt {
                    padding-left: 46px;

                    @include SP {
                        padding-left: 0;
                    }
                }

                .link-txt:after {
                    width: 34px;
                    height: 31px;
                    background-size: 34px 31px;
                    left: -1px;
                    top: 53%;
                }

                .link-txt:before {
                    width: 34px;
                    height: 31px;
                    background-size: 34px 31px;
                    left: -1px;
                    top: 53%;
                }
            }
        }

        a {
            @include font(20, 21, 70);
            padding: 23px 0;

            @include SP {
                @include font(18);
                padding: 13px 0 18px;
                height: 100%;
                border-radius: 4px;
            }
        }

        .wcm02 {
            @include SP {
                padding: 0;
            }
        }
    }

    .m-inner {
        padding: 58px 0 0;
        overflow-y: scroll;

        @include SP {
            padding: 11px 0 50px;
        }
    }

    .m-menu {
        color: #231815;
        font-weight: 500;

        @include SP {
            margin-bottom: 45px;
        }

        a {
            @include HOVER {
                &:hover {
                    opacity: 0.65;
                }
            }
        }

        .wcm02 {
            @include SP {
                padding: 0;
            }
        }

        .menu-link {
            padding-bottom: 15px;
            border-bottom: 1px solid #e2e2e2;
            @include font(20, 23, 100);

            @include max-screen(900px) {
                @include font(16);
                padding-bottom: 10px;
            }

            @include SP {
                @include letter-spacing(20);
                padding-bottom: 0;
            }

            a {
                @include SP {
                    padding: 20px;
                    display: block;
                }
            }
        }

        .menu-group {
            padding: 30px 0 33px;
            border-bottom: 1px solid #e2e2e2;
            @include flexbox;
            @include flex-wrap(wrap);
            @include font(20, 28, 100);

            @include max-screen(900px) {
                @include font(16);
                padding: 20px 0;
            }

            .gnavi-item {
                width: 33.333%;

                &:nth-child(2) {
                    padding-left: 40px;

                    @include max-screen(900px) {
                        padding-left: 15px;
                    }

                    @include ipad {
                        padding-left: 20px;
                    }
                }

                &:last-of-type {
                    padding-left: 55px;

                    @include max-screen(900px) {
                        padding-left: 15px;
                    }

                    @include ipad {
                        padding-left: 20px;
                    }
                }

                a {
                    @include flexbox;
                    @include align-items(center);
                }

                &__img {
                    width: 130px;

                    @include max-screen(900px) {
                        width: 45%;
                    }
                }

                &__txt {
                    width: calc(100% - 130px);
                    padding-left: 15px;

                    @include max-screen(900px) {
                        width: 55%;
                        padding-left: 10px;
                    }
                }
            }
        }

        .menu-item {
            padding: 30px 0 26px;
            border-bottom: 1px solid #e2e2e2;
            @include flexbox;

            @include max-screen(900px) {
                padding: 20px 0;
            }

            &__main {
                width: 200px;
                @include font(20, 30, 100);

                @include max-screen(900px) {
                    @include font(16);
                }
            }

            &__sub {
                width: calc(100% - 200px);
                @include flexbox;
                @include font(18, 34, 50);
                padding-left: 63px;

                @include max-screen(900px) {
                    @include font(14);
                    padding-left: 30px;
                }

                ul {
                    width: 46%;
                }
            }
        }

        .menu-link02 {
            padding: 30px 0;
            border-bottom: 1px solid #e2e2e2;
            @include font(20, 30, 100);

            @include max-screen(900px) {
                @include font(16);
                padding: 20px 0;
            }
        }

        .menu-toggle {
            border-bottom: 1px solid #e2e2e2;

            &__ttl {
                position: relative;
                padding: 0 20px 0 0;

                a {
                    @include SP {
                        display: block;
                        width: 90%;
                        padding: 15px 0 15px 20px;
                    }
                }

                span {
                    position: absolute;
                    right: 20px;
                    top: 18px;
                    width: 14px;
                    height: 14px;
                    display: block;

                    &:after,
                    &:before {
                        content: "";
                        width: 14px;
                        height: 1px;
                        background: $color_body;
                        position: absolute;
                    }

                    &:after {
                        right: 0;
                        top: 50%;
                        @include transform(translateY(-50%));
                    }

                    &:before {
                        right: 0;
                        top: 50%;
                        @include transform(rotate(90deg) translateY(-50%));
                    }

                    &.is-active {
                        &:before {
                            opacity: 0;
                        }
                    }
                }
            }

            &__content {
                padding: 2px 10px 0;
                display: none;

                @include ANDR {
                    padding: 0 28px 8px;
                    margin-top: -10px;
                }

                li {
                    @include ANDR {
                        margin-bottom: -5px;
                    }
                }
            }

            li {
                a {
                    @include font(16, 40, 50);
                    display: block
                }
            }
        }
    }

    .m-other {
        margin-top: 28px;

        @include max-screen(900px) {
            margin-top: 15px;
        }

        @include SP {
            margin-top: 40px;
        }

        .wcm02 {
            @include flexbox;
            @include flex-wrap(wrap);
            @include justify-content(space-between);
            @include align-items(center);

            @include SP {
                display: block;
            }
        }

        .other-social {
            padding-right: 20px;

            @include SP {
                padding-right: 0;
            }

            ul {
                @include flexbox;
                @include flex-wrap(wrap);

                @include SP {
                    @include justify-content(center);
                }
            }

            li {
                margin-right: 30px;

                @include max-screen(900px) {
                    margin-right: 15px;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                @include SP {
                    margin: 0;
                    padding: 0 20px;
                }
            }

            a {
                display: block;
                padding-left: 35px;
                position: relative;
                @include font(17, 60, 60);
                @include ffMon;

                @include max-screen(900px) {
                    padding-left: 24px;
                    @include font(13);
                }

                @include SP {
                    padding-left: 0;
                }

                @include HOVER {
                    &:hover {
                        opacity: 0.65;
                    }
                }

                &:before {
                    content: "";
                    width: 28px;
                    height: 28px;
                    position: absolute;
                    left: 0;
                    top: 48%;
                    @include transform(translateY(-50%));

                    @include max-screen(900px) {
                        width: 20px;
                        height: 20px;
                    }

                    @include SP {
                        display: none;
                    }
                }
            }

            .facebook a:before {
                background: url($img_url + "img/common/icon/ico_facebook.svg") no-repeat center;
                background-size: cover;
            }

            .twitter a:before {
                background: url($img_url + "img/common/icon/ico_twitter.svg") no-repeat center;
                background-size: cover;
            }

            .youtube a:before {
                background: url($img_url + "img/common/icon/ico_youtube.svg") no-repeat center;
                background-size: cover;
            }
        }

        .other-link {
            padding-left: 20px;

            @include SP {
                display: none;
            }

            ul {
                @include flexbox;
            }

            li {
                margin-right: 20px;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            a {
                text-decoration: underline;
                @include font(13, 24, 60);

                @include HOVER {
                    &:hover {
                        opacity: 0.65;
                    }
                }
            }
        }
    }

    .m-action {
        text-align: center;
        @include ffMon;
        @include font(16, 19, 180, 400);
        margin-top: 52px;
        cursor: pointer;

        @include SP {
            margin-top: 30px;
        }

        @include HOVER {
            &:hover {
                opacity: 0.65;
            }
        }

        span {
            display: inline-block;
            position: relative;
            padding-right: 22px;

            &:after {
                content: "";
                width: 14px;
                height: 15px;
                position: absolute;
                right: 0;
                top: 50%;
                @include transform(translateY(-50%));
                background: url($img_url + "img/common/icon/ico_close.png") no-repeat center;
                background-size: cover;
            }
        }
    }
}