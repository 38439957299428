@charset 'UTF-8';

/* Layout
======================================================*/
.wcm02 {
  max-width: 1040px;
  padding: 0 20px;
  margin: 0 auto;
  @include SP {
    max-width: 100%;
    padding: 0 18px;
  }
}
.l-subpage {
  position: relative;
  &:after {
    content: "";
    width: 25%;
    height: 150%;
    background: #faf2f0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    @include min-screen(1650px) {
      width: 30%;
    }
    @include SP {
      width: calc(100% - 80px);
    }
  }
}

/* Breadcrumb
======================================================*/
.breadcrumb {
  margin-top: 15px;
  @include SP {
    margin-top: 6px;
  }
  ul {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    li {
      display: inline;
      margin-left: 6px;
      color: rgba(66, 67, 68, 0.8);
      @include font-size(13);
      @include letter-spacing(40);
      font-weight: bold;
      line-height: 1;
      &:first-of-type {
        margin-left: 0;
      }
      @include SP {
        @include font-size(11);
        margin-left: 4px;
      }
    }
    a {
      display: inline-block;
      color: rgba(66, 67, 68, 0.8);
      @include ffYu;
      span {
        display: inline-block;
        padding: 0 3px 0 0;
        border-bottom: 1px solid rgba(66, 67, 68, 0.8);
        @include SP {
          padding: 0 1px 0 0;
        }
      }
    }
  }
}

/* keyvisual
======================================================*/
.keyvisual {
  background: url($img_url + "img/common/other/sub_visual.png") no-repeat left
    center;
  background-size: cover;
  margin-top: 20px;
  @include SP {
    background: url($img_url + "img/common/other/sub_visual_sp.png") no-repeat
      left center;
    background-size: cover;
    margin-top: 0;
  }
  &-ttl {
    padding: 23px 0 12px;
    @include flexbox;
    @include align-items(center);
    font-weight: bold;
    @include SP {
      display: block;
      text-align: center;
      padding: 23px 0 18px;
    }
    &__en {
      color: $orange;
      @include font-size(42);
      @include letter-spacing(110);
      @include ffJose;
      text-transform: uppercase;
      display: block;
      @include SP {
        @include font-size(31);
      }
    }
    &__ja {
      @include ffYu;
      @include letter-spacing(40);
      color: rgba(66, 67, 68, 8);
      padding-left: 10px;
      display: block;
      @include SP {
        @include font-size(15);
        margin-top: -6px;
        padding-left: 0;
      }
    }
  }
}

/* Title
======================================================*/
.cmn-title01 {
  color: $orange;
  font-weight: bold;
  &__en {
    @include ffJose;
    @include font-size(34);
    @include letter-spacing(40);
    @include SP {
      @include font-size(28);
    }
  }
  &__ja {
    @include font(14, 45, 40);
    padding-left: 7px;
    @include SP {
      @include font(14, 32, 40);
    }
  }
}
.cmn-title02 {
  @include font(36, 64, 40, bold);
  text-align: center;
  @include SP {
    @include font(25, 40, 40);
  }
}
.cmn-title03 {
  background: #f1f1f1;
  border-radius: 5px;
  @include font(28, 28, 40, bold);
  padding: 26px 35px;
  @include SP {
    @include font(20, 30, 40);
    padding: 12px 14px;
  }
  &--orange {
    background: #fdd4c8;
  }
}
.cmn-title04 {
  text-align: center;
  &__number {
    color: $orange02;
    @include ffJose;
    margin-bottom: 80px;
    overflow: hidden;
    .en-txt {
      @include font-size(22);
      @include letter-spacing(110);
      display: block;
      font-weight: bold;
      margin-bottom: 57px;
      @include max-screen(900px) {
        @include font(18);
        margin-bottom: 40px;
      }
      @include SP {
        @include font(13);
      }
    }
    .number {
      @include font(124, 1, 50);
      font-weight: 600;
      display: block;
      @include max-screen(900px) {
        @include font(90);
      }
      @include SP {
        @include font(79);
      }
    }
  }
  &__content {
    @include font(50, 84, 40, bold);
    display: inline-block;
    margin-top: 105px;
    position: relative;
    @include max-screen(900px) {
      margin-top: 65px;
      @include font(35);
    }
    @include SP {
      @include font(43);
      margin-top: 59px;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      @include transform(translateY(-50%));
      @include SP {
        left: -19px;
      }
    }
  }
  .lbl-chart {
    padding-left: 70px;
    @include SP {
      padding-left: 42px;
    }
    &:before {
      width: 48px;
      height: 65px;
      background: url($img_url + "img/strength/ico_chart.svg") no-repeat center;
      background-size: cover;
      margin-top: -3px;
      @include SP {
        width: 43px;
        height: 58px;
      }
    }
  }
  .lbl-money {
    padding-left: 85px;
    @include SP {
      padding-left: 80px;
    }
    &:before {
      width: 67px;
      height: 69px;
      background: url($img_url + "img/strength/ico_money.svg") no-repeat center;
      background-size: cover;
      margin-top: -3px;
      left: 0;
      @include SP {
        width: 67px;
        height: 70px;
        margin-top: 3px;
      }
    }
  }
  .lbl-staff {
    padding-left: 82px;
    &:before {
      width: 65px;
      height: 62px;
      background: url($img_url + "img/strength/ico_staff.svg") no-repeat top
        center;
      background-size: 65px 62px;
      margin-top: -3px;
      left: 0;
      @include SP {
        width: 66px;
        height: 63px;
        background: url($img_url + "img/strength/ico_staff.svg") no-repeat top
          center;
        background-size: 66px 63px;
        margin-top: 5px;
      }
    }
  }
}
.cmn-title05 {
  text-align: center;
  &__en {
    display: block;
    @include font(42, 40, 110, bold);
    @include ffJose;
    color: $orange;
    @include SP {
      @include font(36, 44, 70);
    }
  }
  &__ja {
    display: block;
    @include font(18, 15, 40, bold);
    margin-top: 10px;
    @include SP {
      line-height: 28px;
    }
  }
}
.cmn-title06 {
  text-align: center;
  &__ja {
    display: block;
    font-weight: 500;
    @include font-size(44);
    @include letter-spacing(80);
    @include SP {
      @include font-size(32);
    }
  }
  &__en {
    display: block;
    @include ffJose;
    font-weight: 700;
    color: $orange;
    @include font-size(20);
    @include letter-spacing(110);
    margin-top: 5px;
    @include SP {
      @include font-size(18);
    }
  }
}

/* Button
======================================================*/
@keyframes fillColor {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1) translateX(100%);
  }
}
@-webkit-keyframes fillColor {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1) translateX(100%);
  }
}

@keyframes fillColor02 {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
    background: $white;
  }
}
@-webkit-keyframes fillColor02 {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
    background: $white;
  }
}

@keyframes fillColor03 {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
    background: $color_body;
  }
}
@-webkit-keyframes fillColor03 {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
    background: $color_body;
  }
}
.cmn-btn01 {
  text-align: center;
  .cs-link {
    display: block;
    width: 298px;
    height: 65px;
    background: #fff;
    padding: 17px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: $orange;
    @include font-size(18);
    @include letter-spacing(40);
    font-weight: bold;
    @include border-radius(40px);
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $orange02;
      z-index: 1;
      @include transform(scaleX(0));
      transform-origin: left center;
      // animation: fillColor02 .4s;
      @include border-radius(40px);
    }
    &:after {
      content: "";
      position: absolute;
      right: 35px;
      top: calc(50% - 6px);
      width: 12px;
      height: 12px;
      border-left: 2px solid $orange02;
      border-top: 2px solid $orange02;
      @include transform(rotate(135deg));
      z-index: 1;
    }
    span {
      display: inline-block;
      position: relative;
      z-index: 2;
    }
    @include HOVER {
      &:hover {
        opacity: 1;
        -ms-filter: "alpha(opacity=100)";
        filter: alpha(opacity=100);
        color: $white;
        &:before {
          -webkit-transition: 0.2s ease all;
          transition: 0.2s ease all;
          transform: scaleX(1);
          animation: none;
        }
        &:after {
          border-left: 2px solid $white;
          border-top: 2px solid $white;
        }
      }
    }
  }
  &--border {
    .cs-link {
      width: 219px;
      height: 50px;
      padding: 12px 0;
      border: 1px solid $color_body;
      color: $color_body;
      @include font-size(15);
      @include HOVER {
        &:hover {
          border-color: $orange02;
        }
      }
      &:before {
        background: $orange02;
      }
      &:after {
        width: 8px;
        height: 8px;
        border-left: 2px solid $color_body;
        border-top: 2px solid $color_body;
        top: calc(50% - 4px);
      }
    }
  }
  &--black {
    .cs-link {
      width: 228px;
      height: 50px;
      padding: 12px 0;
      border: 1px solid $color_body;
      background: $color_body;
      color: $white;
      @include font-size(15);
      &:before {
        background: $orange02;
        animation: fillColor03 0.4s;
      }
      &:after {
        width: 8px;
        height: 8px;
        border-left: 2px solid $white;
        border-top: 2px solid $white;
        top: calc(50% - 4px);
      }
      @include HOVER {
        &:hover {
          border: 1px solid $orange02;
          background: $white;
        }
      }
    }
  }
  &--orange {
    .cs-link {
      width: 493px;
      height: 80px;
      padding: 20px 0;
      border: 1px solid #ed511c;
      background: #ed511c;
      color: $white;
      @include font-size(24);
      &:before {
        background: $white;
      }
      &:after {
        width: 8px;
        height: 8px;
        border-left: 2px solid $white;
        border-top: 2px solid $white;
        top: calc(50% - 4px);
      }
      @include HOVER {
        &:hover {
          border: 1px solid $orange02;
          background: $white;
          color: $orange02;
          &:after {
            border-left: 2px solid $orange02;
            border-top: 2px solid $orange02;
          }
        }
      }
    }
  }
}

/* List
======================================================*/
.cmn-list01 {
  @include flexbox;
  @include flex-wrap(wrap);
  @include SP {
    display: block;
  }
  li {
    padding: 0 12px 12px 0;
    width: calc(50% - 18px);
    margin-bottom: 15px;
    position: relative;
    transition: all 0.3s ease;
    @include SP {
      padding: 0 7px 7px 0;
      margin-bottom: 5px;
    }
    &:after {
      content: "";
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      position: absolute;
      right: 0;
      bottom: 0;
      background: rgba(19, 20, 21, 0.04);
      transition: all 0.3s ease;
      @include SP {
        width: calc(100% - 7px);
        height: calc(100% - 7px);
      }
    }
    &:nth-child(2n) {
      margin-left: 18px;
      @include SP {
        margin-left: 0;
      }
    }
    &:nth-child(2n + 1) {
      margin-right: 18px;
      @include SP {
        margin-right: 0;
      }
    }
    @include HOVER {
      &:hover {
        &:after {
          background: $orange02;
        }
        a {
          opacity: 1;
          background: $organe04;
          color: $color_body;
        }
      }
    }
    @include SP {
      width: 100%;
    }
    a {
      position: relative;
      display: block;
      height: 100%;
      border: 1px solid $grey;
      background: $white;
      z-index: 1;
      text-align: left;
      padding: 18px 15px 18px 60px;
      &:before {
        content: "";
        width: 35px;
        height: 32px;
        background: url($img_url + "img/common/icon/ico_check.svg") no-repeat
          center;
        background-size: 33px 32px;
        position: absolute;
        left: 16px;
        top: 20px;
        @include SP {
          width: 26px;
          height: 23px;
          background: url($img_url + "img/common/icon/ico_check.svg") no-repeat
            center;
          background-size: 26px 23px;
          left: 9px;
          top: 50%;
          @include transform(translateY(-50%));
          margin-top: -3px;
        }
      }
      @include SP {
        padding: 6px 5px 10px 42px;
      }
      @include IP5 {
        padding: 10px 0px 10px 42px;
      }
    }
  }
  &__txt {
    @include font(23);
    font-weight: 400;
    margin-bottom: -1px;
    position: relative;
    @include SP {
      @include font(16);
    }
    @include ANDR {
      @include font(14);
    }
    span {
      font-weight: bold;
    }
  }
  &__link {
    @include font-size(17);
    font-weight: 500;
    color: $orange;
    position: relative;
    border-bottom: 1px solid $orange;
    &:after {
      content: "";
      width: 9px;
      height: 9px;
      background: url($img_url + "img/common/icon/ico_arrow_orange.svg")
        no-repeat center;
      background-size: 9px 9px;
      position: absolute;
      right: -12px;
      top: 60%;
      @include transform(translateY(-50%));
    }
    @include SP {
      @include font(12, 18, 0);
    }
  }
}
.cmn-list02 {
  counter-reset: my-counter;
  li {
    @include font(16, 32, 40, 400);
    margin-bottom: 30px;
    @include SP {
      padding-left: 0;
      text-indent: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:nth-child(1) {
      .cmn-list02__title:before {
        content: "(１)";
      }
    }
    &:nth-child(2) {
      .cmn-list02__title:before {
        content: "(２)";
      }
    }
    &:nth-child(3) {
      .cmn-list02__title:before {
        content: "(３)";
      }
    }
  }

  &__title {
    padding-left: 32px;
    position: relative;
    @include SP {
      padding-left: 0;
      text-indent: 32px;
    }
    &:before {
      @include font(16, 32, 40, 400);
      position: absolute;
      left: 0;
      top: -1px;
      @include SP {
        left: -30px;
        top: 0;
      }
    }
  }
  &__info {
    padding-left: 15px;
    @include SP {
      padding-left: 0;
      text-indent: 17px;
    }
  }
}
.cmn-list-anchor {
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(center);
  a {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include align-items(center);
    border: 1px solid $color_body;
    text-decoration: none;
    margin: 0 9px 18px;
    padding: 17px 21px 19px;
    max-width: 335px;
    width: 100%;
    overflow: hidden;
    color: $color_body;
    background: #fff;
    @include font-size(18);
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
    position: relative;
    @include PC {
      &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: $color_body;
        z-index: 1;
        transform-origin: left center;
        transition: 0.3s 0.3s;
        transform: scaleX(0);
      }
      &:after {
        position: absolute;
        content: "";
        background: #fff;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        transition: 0.3s;
      }
    }
    > span {
      display: block;
      width: 100%;
      position: relative;
      z-index: 3;
      padding: 0 13px;
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        width: 8px;
        height: 8px;
        border-right: 2px solid $color_body;
        border-top: 2px solid $color_body;
        @include transform(rotate(135deg));
        z-index: 1;
        margin-top: -4px;
      }
    }
    @include HOVER {
      &:hover {
        color: #fff;
        opacity: 1;
        &:before {
          transition: 0.3s;
          transform: scaleX(1);
        }
        &:after {
          // transition: scaleX .3s;
          // transform: scaleX(0);
          transition: 0s;
          width: 0;
        }
        span:after {
          transition: 0.1s 0.1s;
          border-right-color: #fff;
          border-top-color: #fff;
        }
      }
    }
    @include SP {
      max-width: 100%;
      @include font-size(14);
      padding: 13px 8px 15px;
      span {
        padding: 0 9px;
        &:after {
          width: 7px;
          height: 7px;
        }
      }
    }
    @include ANDR {
      @include font-size(13);
    }
    @include IP5 {
      @include font-size(12);
    }
  }
  @include SP {
    @include justify-content(space-between);
    a {
      width: 48.2%;
      margin: 0 0 14px;
    }
  }
}

/* Table
======================================================*/
.cmn-table {
  box-sizing: border-box;
  border: 1px solid #8a8f95;
  .row {
    display: table;
    width: 100%;
  }
  .tb-title,
  .tb-data {
    border-collapse: collapse;
    border-bottom: 1px solid #8a8f95;
    @include font(16, 32, 40);
    display: table-cell;
  }
  .tb-title {
    width: 23.1%;
    max-width: 231px;
    text-align: left;
    font-weight: bold;
    background: #f1f1f1;
    padding: 13px 19px;
    @include SP {
      width: 100%;
      max-width: 100%;
      display: block;
      border: none;
      padding: 9px 15px;
    }
  }
  .tb-data {
    width: calc(100% - 23.1%);
    padding: 13px 28px;
    @include SP {
      width: 100%;
      display: block;
      border: none;
      border-bottom: 1px solid #8a8f95;
      padding: 11px 14px 14px;
    }
  }
  .row:last-of-type {
    .tb-title,
    .tb-data {
      border-bottom: none;
    }
  }
}
.cmn-table02 {
  max-width: 996px;
  margin: 0 auto;
  @include SP {
    max-width: 100%;
  }
  .row {
    display: table;
    width: 100%;
  }
  .tb-title,
  .tb-content {
    border-collapse: collapse;
    display: table-cell;
  }
  .tb-title {
    width: 162px;
    @include font(18);
    @include letter-spacing(20);
    font-weight: 500;
    background: #f1f1f1;
    color: $orange;
    text-align: center;
    border-bottom: 2px solid $white;
    vertical-align: middle;
    @include SP {
      @include font-size(16);
      width: 78px;
    }
  }
  .tb-content {
    width: calc(100% - 162px);
    @include font(16, 32, 40, 400);
    border-bottom: 2px solid #f1f1f1;
    padding: 20px 30px;
    @include SP {
      width: calc(100% - 78px);
      @include font(15, 25, 0);
      padding: 22px 10px 22px 12px;
    }
  }
  .row:last-of-type {
    .tb-title,
    .tb-content {
      border-bottom: none;
    }
  }
}

/* Slide
======================================================*/
.main-slider {
  overflow: hidden;
  position: relative;

  .slick-large {
    margin-bottom: 7px;
    @include SP {
      margin-bottom: -5px;
    }
    .slick-prev {
      background: url($img_url + "img/common/other/ico_prev.png") no-repeat
        center;
      background-size: cover;
    }
    .slick-next {
      background: url($img_url + "img/common/other/ico_next.png") no-repeat
        center;
      background-size: cover;
      right: 0;
    }
  }

  .slick-thumb {
    .slick-track {
      width: 100% !important;
    }
    .slick-slide {
      width: calc(20% - 16px) !important;
      margin-right: 20px;
      cursor: pointer;
      @include SP {
        width: calc(20% - 5.6px) !important;
        margin-right: 7px;
      }
      img {
        opacity: 0.4;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &.slick-current {
        img {
          opacity: 1;
        }
      }
      @include HOVER {
        &:hover {
          img {
            opacity: 1;
          }
        }
      }
    }
    .thumb-txt {
      @include font(16, 24, 0, 400);
      text-align: center;
      margin-top: 7px;
      @include max-screen(1200px) {
        @include font(14);
      }
      @include SP {
        @include font(11);
      }
      span {
        @include font-size(14);
        display: block;
        margin-top: -2px;
        @include SP {
          @include font(11);
          margin-top: 0;
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    display: block;
    width: 38px;
    height: 66px;
    position: absolute;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    color: transparent;
    top: 50%;
    @include transform(translateY(-50%));
    padding: 0;
    border: none;
    outline: none;
    z-index: 999;
    @include HOVER {
      &:hover {
        opacity: 0.65;
      }
    }
    @include SP {
      width: 22px;
      height: 38px;
      margin-top: -4px;
    }
  }
}

/* Other
======================================================*/
.cmn-about {
  background: #fdd4c8;
  padding: 85px 20px 80px;
  @include SP {
    padding: 48px 18px 115px;
  }
  &__inner {
    max-width: 886px;
    margin: 0 auto;
    @include flexbox;
    @include flex-wrap(wrap);
    @include SP {
      max-width: 100%;
      display: block;
    }
  }
  .inImage {
    overflow: hidden;
  }
  .c-item {
    width: calc(25% - 6.75px);
    margin: 0 9px 20px 0;
    @include SP {
      margin: 0 0 11px;
      width: 100%;
    }
    &:last-of-type {
      margin-right: 0;
    }
    & > a {
      display: block;
      @include HOVER {
        &:hover {
          opacity: 1;
          .cs-link {
            border: 1px solid $orange02;
            &:before {
              -webkit-transition: 0.2s ease all;
              transition: 0.2s ease all;
              transform: scaleX(1);
              animation: none;
            }
          }
          .c-item__img {
            @include transform(scale(1.1));
          }
        }
      }
      @include SP {
        @include flexbox;
        @include flex-wrap(wrap);
      }
    }
    &__img {
      height: 111px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      z-index: 2;
      transition: all 0.3s ease-out;
      @include SP {
        width: 130px;
        height: 100%;
      }
    }
    &__info {
      padding: 28px 0 27px;
      background: #f8f8f8;
      text-align: center;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      @include SP {
        width: calc(100% - 130px);
        padding: 23px 0 14px;
      }
    }
    .ttl-en {
      @include font(20, 19, 50, bold);
      @include ffJose;
      margin-bottom: 8px;
      @include max-screen(950px) {
        @include font-size(17);
      }
      @include SP {
        @include font(17, 20, 200);
        margin-bottom: 0;
        margin-left: -10px;
      }
    }
    .ttl-ja {
      @include font(13, 19, 140, 400);
      margin-bottom: 16px;
      @include max-screen(950px) {
        @include font-size(11);
      }
      @include SP {
        @include font(13, 30, 140);
        margin-bottom: 0;
        margin-left: -10px;
      }
      @include IP5 {
        @include font(12, 30, 50);
      }
    }
    .cs-link {
      width: 134px;
      height: 38px;
      @include font-size(12);
      margin: 0 auto;
      padding: 8px 0;
      &:after {
        right: 12px;
      }
      @include SP {
        display: none;
      }
    }

    &--message {
      .c-item__img {
        background-image: url($img_url + "img/aboutus/common/com_img01.jpg");
        @include SP {
          background-image: url($img_url + "img/aboutus/common/com_img01_sp.jpg");
        }
      }
    }
    &--mission {
      .c-item__img {
        background-image: url($img_url + "img/aboutus/common/com_img02.jpg");
        @include SP {
          background-image: url($img_url + "img/aboutus/common/com_img02_sp.jpg");
        }
      }
      .c-item__info {
        @include SP {
          padding: 16px 0 4px;
        }
      }
    }
    &--overview {
      .c-item__img {
        background-image: url($img_url + "img/aboutus/common/com_img03.jpg");
        @include SP {
          background-image: url($img_url + "img/aboutus/common/com_img03_sp.jpg");
        }
      }
    }
    &--history {
      margin-right: 0;
      .c-item__img {
        background-image: url($img_url + "img/aboutus/common/com_img04.jpg");
        @include SP {
          background-image: url($img_url + "img/aboutus/common/com_img04_sp.jpg");
        }
      }
    }
    &--access {
      margin-bottom: 0;
      @include SP {
        margin-bottom: 11px;
      }
      .c-item__img {
        background-image: url($img_url + "img/aboutus/common/com_img05.jpg");
        @include SP {
          background-image: url($img_url + "img/aboutus/common/com_img05_sp.jpg");
        }
      }
    }
    &--gallery {
      margin-bottom: 0;
      background: $white;
      @include SP {
        margin-bottom: 11px;
      }
      a {
        opacity: 0.65;
        pointer-events: none;
      }
      .c-item__img {
        background-image: url($img_url + "img/aboutus/common/com_img07.jpg");
        @include SP {
          background-image: url($img_url + "img/aboutus/common/com_img07_sp.jpg");
        }
      }
    }
    &--contact {
      margin-bottom: 0;
      .c-item__img {
        background-image: url($img_url + "img/aboutus/common/com_img08.jpg");
        @include SP {
          background-image: url($img_url + "img/aboutus/common/com_img08_sp.jpg");
        }
      }
    }
    &--contents {
      margin-bottom: 0;
      @include SP {
        margin-bottom: 11px;
      }
      .c-item__img {
        background-image: url($img_url + "img/service/com_img01.jpg");
        @include SP {
          background-image: url($img_url + "img/service/com_img01_sp.jpg");
        }
      }
    }
    &--schedule {
      margin-bottom: 0;
      @include PC {
        .ttl-en {
          padding-top: 7px;
        }
      }
      @include SP {
        .c-item__info {
          padding-top: 28px;
          padding-bottom: 26px;
        }
      }
      .c-item__img {
        background-image: url($img_url + "img/service/com_img02.jpg");
        @include SP {
          background-image: url($img_url + "img/service/com_img02_sp.jpg");
        }
      }
    }
  }
  &--service {
    padding-top: 86px;
    padding-bottom: 86px;
    @include SP {
      padding-top: 62px;
      padding-bottom: 129px;
    }
    .c-item {
      .ttl-en {
        line-height: 1.1;
        margin-bottom: 0;
        @include PC {
          min-height: 44px;
          @include height-match();
        }
        @include SP {
          height: auto !important;
          margin-left: 3px;
          margin-bottom: -2px;
        }
        @include IP5 {
          @include font-size(15);
          @include letter-spacing(70);
        }
      }
      .ttl-ja {
        @include SP {
          margin-left: 3px;
        }
        @include IP5 {
          @include font-size(11);
        }
      }
    }
    .cmn-about__inner {
      @include justify-content(center);
    }
  }
}
.cmn-anchor {
  .wcm02 {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include SP {
      max-width: 100%;
      padding: 0 18px;
    }
  }
  .anchor-item {
    max-width: 248px;
    width: 100%;
    margin-left: 15px;
    &:first-of-type {
      margin-left: 0;
      @include SP {
        margin-right: 7.5px;
      }
    }
    &:last-of-type {
      @include SP {
        margin-left: 7.5px;
      }
    }
    @include SP {
      width: calc(50% - 7.5px);
      max-width: 100%;
    }
    a {
      display: block;
      text-align: center;
      @include font(18, 27, 0, 500);
      border: 1px solid $color_body;
      padding: 18px 0 17px;
      position: relative;
      overflow: hidden;
      @include SP {
        @include font(14, 20, 0);
        padding: 14px 0 15px;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: $color_body;
        z-index: -1;
        @include transform(scaleX(0));
        transform-origin: left center;
        animation: fillColor 0.4s;
      }
      &:after {
        content: "";
        position: absolute;
        right: 22px;
        top: calc(50% - 5px);
        width: 7px;
        height: 7px;
        border-left: 2px solid $color_body;
        border-top: 2px solid $color_body;
        @include transform(rotate(135deg) rotate(90deg));
        z-index: 1;
        @include SP {
          right: 12px;
        }
      }
      @include HOVER {
        &:hover {
          opacity: 1;
          -ms-filter: "alpha(opacity=100)";
          filter: alpha(opacity=100);
          color: $white;
          &:before {
            -webkit-transition: 0.2s ease all;
            transition: 0.2s ease all;
            transform: scaleX(1);
            animation: none;
          }
          &:after {
            border-left: 2px solid $white;
            border-top: 2px solid $white;
          }
        }
      }
    }
  }
}
.is-clamp2 {
  @include multiLineEllipsis(2);
  @include IE {
    @include multiLineEllipsisForFF(22px, 2);
  }
}

/* COMMON POST
 * -----------------------------------------------*/
.date {
  letter-spacing: 0;
  @include ffJose;
  font-weight: 400;
  color: rgba($color_body, 0.6);
}
.cate {
  @include font-size(14);
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0;
  span {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    color: #fff;
    background: $color_body;
    margin: 0 2px 2px 0;
    padding: 6px 10px 8px;
    min-width: 75px;
    &:last-child {
      margin-right: 0;
    }
  }
  @include SP {
    span {
      padding: 6px 9px 7px;
    }
  }
}
.cate-name {
  letter-spacing: 0;
  @include font-size(15);
  @include SP {
    @include font-size(16);
    @include letter-spacing(40);
  }
}
.list-blog {
  .item {
    @include flexbox();
    @include flex-wrap(wrap);
    margin-bottom: 64px;
    &:last-child {
      margin-bottom: 0;
    }
    @include SP {
      display: block;
      margin-bottom: 45px;
    }
    a {
      display: block;
      @include HOVER {
        &:hover {
          opacity: 0.5;
        }
      }
    }
    &-img {
      width: 35.097%;
      a {
        @include aspect-ratio(700, 438);
      }
      @include SP {
        width: 100%;
        margin-bottom: 22px;
        a {
          @include aspect-ratio(678, 424);
        }
      }
    }
    &-info {
      width: 64.903%;
      padding-left: 5%;
      @include SP {
        padding: 0;
        width: 100%;
      }
      .tit {
        @include font-size(24);
        font-weight: bold;
        margin-top: 27px;
        @include SP {
          @include font-size(18);
          letter-spacing: 0;
          line-height: 1.8;
          margin-top: 12px;
        }
      }
      .desc {
        margin-top: 11px;
        line-height: 2;
        @include multiLineEllipsis(3);
        @include font(16, 32, 40, 400);
        @include IE {
          @include multiLineEllipsisForFF(32px, 3, #faf2f0);
        }
        @include SP {
          margin-top: 7px;
        }
      }
    }
  }
  .no-image {
    border: 1px solid #c3c3c3;
  }
}
.widget {
  &-wrap {
    @include flexbox();
    @include justify-content(flex-end);
    @include SP {
      @include justify-content(space-between);
    }
  }
  &-box {
    position: relative;
    min-width: 300px;
    width: 30%;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
    @include SP {
      width: 48.9%;
      min-width: auto;
      margin: 0;
    }
  }
  &-ttl {
    position: relative;
    cursor: pointer;
    @include border-radius(5px);
    @include font-size(14);
    letter-spacing: 0;
    min-height: 54px;
    padding: 16px 40px 14px 29px;
    background: #f1f1f1;
    &:before {
      position: absolute;
      content: "";
      top: 50%;
      right: 22px;
      width: 14px;
      height: 18px;
      background: url($img_url + "img/common/icon/ico_arrow_down.svg") no-repeat
        right center;
      background-size: 100% 100%;
      margin-top: -9px;
    }
    &.is-show {
      &:before {
        transition: 0.3s;
        @include transform(rotate(180deg));
      }
    }
    @include IE {
      padding-top: 18px;
    }
    @include SP {
      @include font-size(12);
      padding: 11px 10px 10px;
      min-height: 42px;
      &:before {
        right: 9px;
        width: 11px;
        height: 9px;
        margin-top: -5px;
      }
    }
  }
  &-list {
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    display: none;
    @include border-radius(5px);
    @include font-size(14);
    padding: 18px 23px 20px 31px;
    background: #f1f1f1;
    @include SP {
      @include font-size(12);
      padding: 14px 12px 14px 15px;
    }
    &.archive-list {
      @include PC {
        padding: 18px 23px 14px 29px;
      }
      @include SP {
        padding-bottom: 10px;
      }
      > li {
        > a {
          border-bottom: 1px solid #c3c3c3;
          padding-bottom: 10px;
          @include SP {
            padding-bottom: 4px;
          }
        }
      }
      .sub {
        padding: 18px 20px 9px;
        @include SP {
          padding: 13px 12px 7px;
        }
        li {
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    a {
      display: block;
      font-weight: 400;
      letter-spacing: 0;
    }
    > li {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/* element common blog category, date */
.post-meta {
  @include flexbox();
  .cate {
    padding-right: 29px;
    @include flex(0 1 auto);
    @include flexbox();
    @include flex-wrap(wrap);
    @include SP {
      padding-right: 31px;
    }
  }
  .date {
    @include font-size(14);
    margin-top: 5px;
    display: inline-block;
    @include flex(1 0 auto);

    @include IE {
      margin-top: 3px;
    }
  }
}

/* Pagination */
.mod-pagination {
  position: relative;
  padding-top: 102px;
  border-top: 1px solid #8a8f95;
  overflow: hidden;
  min-height: 191px;
  @include SP {
    padding-top: 40px;
    min-height: auto;
  }
  a {
    display: block;
  }
  .btn-base {
    position: relative;
    overflow: hidden;
    max-width: 248px;
    padding: 11px 47px 11px 63px;
    margin: -18px auto 0;
    text-align: center;
    @include border-radius(150px);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    -o-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    -ms-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    outline: 0;
    appearance: none;
    background: #ed511c;
    color: $white;
    @include font(15, 28, 40, bold);
    @include SP {
      margin: 0 auto;
      padding: 11px 40px;
      max-width: 243px;
    }
    span {
      display: inline-block;
      position: relative;
      z-index: 9;
      &:before {
        content: "";
        width: 15px;
        height: 15px;
        background: url($img_url + "img/common/icon/ico_arrow03.svg") no-repeat
          center;
        background-size: 15px 15px;
        position: absolute;
        left: -30px;
        top: 50%;
        @include transform(translateY(-50%));
        @include SP {
          left: -23px;
        }
      }
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $color_body;
      z-index: 1;
      @include transform(scaleX(0));
      transform-origin: left center;
      animation: fillColor 0.4s;
      @include border-radius(40px);
    }
    @include HOVER {
      &:hover {
        opacity: 1;
        -ms-filter: "alpha(opacity=100)";
        filter: alpha(opacity=100);
        &:before {
          -webkit-transition: 0.2s ease all;
          transition: 0.2s ease all;
          transform: scaleX(1);
          animation: none;
        }
      }
    }
  }
  .paging {
    max-width: 200px;
    width: 26%;
    position: relative;
    @include SP {
      max-width: 100%;
      width: 37%;
      margin-top: 112px;
    }
    &-tit {
      font-weight: bold;
      @include multiLineEllipsis(2);
      line-height: 1.75;
      @include IE {
        @include multiLineEllipsisForFF(28px, 2);
      }
      @include SP {
        @include font-size(14);
        line-height: 1.72;
      }
    }
    &-figure {
      margin-bottom: 13px;
      position: relative;
      display: block;
      background: #ccc center no-repeat;
      background-size: cover;
      overflow: hidden;
      @include aspect-ratio(400, 250);
      img {
        position: absolute;
        left: -9999px;
      }
      @include SP {
        @include aspect-ratio(250, 156);
        margin-bottom: 10px;
      }
    }
    &-index {
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      max-width: 282px;
      width: calc(292px - 10px);
      margin: 0 auto;
      @include SP {
        display: block;
        position: static;
        left: auto;
        top: auto;
        width: 84%;
      }
    }
    &-txt {
      position: absolute;
      top: -42px;
      @include ffJose;
      @include font-size(20);
      letter-spacing: 0;
      font-weight: 400;
      line-height: 1.44;
      padding-top: 4px;
      &:before {
        position: absolute;
        content: "";
        top: 0;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
        @include IE {
          width: 29px;
          height: 29px;
        }
        @supports (-ms-ime-align: auto) {
          width: 29px;
          height: 29px;
        }
      }
      @include SP {
        top: -64px;
        @include font-size(16);
      }
    }
    &-prev {
      float: left;
      .paging-txt {
        left: 0;
        padding-left: 44px;
        &:before {
          left: 0;
          background-image: url($img_url + "img/common/icon/ico_prev.svg");
        }
        @include SP {
          left: 0;
          padding: 36px 0 0;
        }
      }
    }
    &-next {
      float: right;
      .paging-txt {
        right: 0;
        padding-right: 44px;
        text-align: right;
        &:before {
          right: 0;
          background-image: url($img_url + "img/common/icon/ico_next.svg");
        }
        @include SP {
          right: 0;
          padding: 36px 0 0;
        }
      }
    }
  }
  .post-meta {
    @include SP {
      margin-top: 2px;
      display: block;
    }
  }
}
.post-main-img {
  text-align: center;
}
.card {
  overflow: hidden;
  &-link {
    display: block;
  }
  &-info {
    padding: 20px 20px 35px 20px;
  }
  &-title {
    @include font-size(24);
    font-weight: bold;
    letter-spacing: 0;
    @include multiLineEllipsis(2);
    @include IE {
      @include multiLineEllipsisForFF(38px, 2);
    }
  }
  @include SP {
    &-title {
      @include font-size(18);
      line-height: 1.8;
    }
  }
}
.js-image,
.js-switch-image {
  display: block;
  background: #ccc center no-repeat;
  background-size: cover;
  overflow: hidden;
  img {
    position: absolute;
    left: -9999px;
  }
}

/* Search
======================================================*/
.cmn-search-block {
  overflow: hidden;
  .wcm02 {
    max-width: 1080px;
  }
  .form-search {
    border: 1px solid #9a9a9a;
    @include border-radius(4px);
  }
  .title-search {
    cursor: pointer;
    text-align: center;
    @include font-size(18);
    font-weight: bold;
    padding: 12px 30px 13px;
    span {
      display: inline-block;
      position: relative;
      padding: 0 43px;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        right: 0;
        width: 14px;
        height: 18px;
        background: url($img_url + "img/common/icon/ico_arrow_down.svg")
          no-repeat 100% 100% /100% 100%;
        margin-top: -9px;
        @include add_prefix(transition, 0.3s ease all);
      }
    }
    &.is-active {
      span:before {
        @include transform_c(rotate(-180deg));
      }
    }
    @include SP {
      text-align: left;
      @include font-size(16);
      padding-right: 28px;
      span {
        display: block;
        padding: 0 20px 0 0;
      }
    }
  }
  .js-drop-search {
    display: none;
  }
  .inner-ct {
    padding: 18px 20px 26px;
    @include SP {
      padding: 17px 16px 19px;
    }
  }
  .search-box {
    max-width: 900px;
    margin: 0 auto;
    .box-item {
      @include clearfix();
      border-bottom: 1px solid #ddd;
      padding-bottom: 12px;
      margin-bottom: 13px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .ttl-box {
      float: left;
      width: 240px;
      font-weight: bold;
      @include letter-spacing(20);
      @include SP {
        float: none;
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .list {
      float: right;
      width: calc(100% - 243px);
      @include flexbox();
      @include flex-wrap(wrap);
      @include SP {
        float: none;
        width: 100%;
      }
    }
  }
  .action-box {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    margin-top: 31px;
    > * {
      margin: 0 7px;
    }
    @include SP {
      margin-top: 21px;
      @include flex-direction(column-reverse);
      > * {
        margin: 5px auto;
      }
    }
  }
  .btn-reset {
    display: block;
    background: #fff;
    border: 1px solid #707070;
    text-align: center;
    font: inherit;
    @include font-size(14);
    width: 159px;
    padding: 10px 10px 13px;
    outline: none;
    @include add_prefix(appearance, none);
    @include border-radius(42px);
    @include add_prefix(box-shadow, none);
    @include IE {
      padding-top: 9px;
    }
    @include SP {
      max-width: 280px;
      width: 100%;
      padding-top: 12px;
    }
  }
  .btn-search {
    display: block;
    width: 305px;
    padding: 8px 28px 12px;
    color: #fff;
    background: $orange02;
    cursor: pointer;
    font: inherit;
    @include font-size(17);
    font-weight: bold;
    outline: none;
    border: none;
    @include add_prefix(appearance, none);
    @include border-radius(42px);
    @include add_prefix(box-shadow, none);
    @include IE {
      padding-top: 8px;
      padding-bottom: 12px;
    }
    span {
      display: block;
      position: relative;
      padding: 0 12px;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        right: 0;
        width: 10px;
        height: 7px;
        background: url($img_url + "img/common/icon/ico_arrow_down01.svg")
          no-repeat 100% 100% / 10px 7px;
        margin-top: -3px;
      }
    }
    @include SP {
      max-width: 280px;
      width: 100%;
      padding: 20px 28px 23px;
    }
  }
  .btn-close {
    text-align: center;
    margin-top: 20px;
    span {
      display: inline-block;
      position: relative;
      padding-top: 31px;
      @include ffJose;
      font-weight: 700;
      @include font-size(15);
      @include letter-spacing(110);
      &:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        left: 0;
        width: 28px;
        height: 28px;
        background: url($img_url + "img/common/icon/ico_close.svg") no-repeat 0
          0;
        margin: 0 auto;
      }
    }
  }
  .checkStyle1 {
    position: relative;
    margin: 0 32px 4px 0;
    @include SP {
      margin: 0 20px 6px 0;
    }
    &:last-child {
      margin-right: 0;
    }
    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      visibility: hidden;
    }
    label {
      position: relative;
      display: inline-block;
      @include font-size(16);
      @include letter-spacing(20);
      font-weight: bold;
      padding-left: 22px;
      @include add_prefix(transition, 0.25s ease all);
      @include SP {
        @include font-size(15);
      }
      &:before {
        position: absolute;
        content: "";
        left: 1px;
        top: 7px;
        width: 12px;
        height: 12px;
        background: #fff;
        border: 1px solid #707070;
        display: block;
      }
      &:after {
        position: absolute;
        content: "";
        left: 3px;
        top: 5px;
        width: 14px;
        height: 11px;
        background: url($img_url + "img/common/icon/ico_check01.svg") no-repeat
          0 0 / 100% 100%;
        display: none;
      }
    }
    input[type="checkbox"]:checked + label {
      &:after {
        display: block;
      }
    }
  }
  .checkStyle2 {
    position: relative;
    margin: 0 8px 8px 0;
    @include HOVER {
      &:hover {
        label {
          color: $white;
          background: $color_body;
        }
      }
    }
    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
    }
    label {
      border: 1px dotted #4d4d4d;
      @include font(12, 20, 60);
      display: block;
      padding: 2px 7px;
      @include add_prefix(transition, 0.25s ease all);
    }
    input[type="checkbox"]:checked + label {
      color: $white;
      background: $color_body;
    }
  }
}
