@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter:not(table),
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

table.aligncenter {
  margin-left: auto;
  margin-right: auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

// CUSTOM

.leftCol {
  width: 70%;
  float: left;
}
.rightCol {
  width: 30%;
  float: right;
}

.cmsContent,
.cms-content {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  word-wrap: break-word;
  ul,
  ol {
    padding-left: 20px;
    margin-bottom: 26px;
    @include SP {
      margin-bottom: 35px;
    }
    li {
      @include font-size(22);
      font-weight: bold;
      @include letter-spacing(40);
      color: #ed5118;
      padding-left: 4px;
      margin-bottom: 7px;
      @include SP {
        @include font-size(17);
        line-height: 1.3;
        margin-bottom: 17px;
      }
    }
  }
  ul {
    &[style*="list-style-type: circle;"] {
      li {
        list-style-type: circle;
      }
    }
    &[style*="list-style-type: square;"] {
      li {
        list-style-type: square;
      }
    }
    li {
      list-style: disc;
    }
  }
  ol {
    &[style*="list-style-type: lower-roman;"] {
      li {
        list-style-type: lower-roman;
      }
    }
    &[style*="list-style-type: lower-alpha;"] {
      li {
        list-style-type: lower-alpha;
      }
    }
    &[style*="list-style-type: lower-greek;"] {
      li {
        list-style-type: lower-greek;
      }
    }
    &[style*="list-style-type: upper-alpha;"] {
      li {
        list-style-type: upper-alpha;
      }
    }
    &[style*="list-style-type: upper-roman;"] {
      li {
        list-style-type: upper-roman;
      }
    }
    li {
      list-style-type: decimal;
    }
  }
  a {
    color: #eb543d;
    text-decoration: underline;
    @include PC {
      &:hover {
        text-decoration: none;
      }
    }
  }
  video,
  iframe {
    max-width: 100% !important;
    height: auto;
  }
  pre {
    white-space: pre-line;
  }
  img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    width: auto;
  }
  h3 {
    @include border-radius(5px);
    @include font-size(28);
    @include letter-spacing(40);
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 41px;
    padding: 19px 34px;
    background: #fdd4c8;
    @include IE {
      padding-bottom: 15px;
    }
    @include SP {
      @include font-size(20);
      margin-bottom: 12px;
      padding: 10px 16px;
    }
  }
  h4 {
    @include font-size(26);
    @include letter-spacing(40);
    line-height: 1.45;
    border-left: 8px solid #ed5118;
    background: #f1f1f1;
    font-weight: bold;
    margin-bottom: 26px;
    padding: 13px 25px 14px;
    @include IE {
      padding-top: 15px;
      padding-bottom: 11px;
    }
    @include SP {
      @include font-size(18);
      border-left-width: 4px;
      padding: 12px 18px 14px;
      margin-bottom: 15px;
    }
  }
  h5 {
    @include font-size(23);
    font-weight: bold;
    margin-bottom: 26px;
    @include SP {
      @include font-size(16);
      margin-bottom: 13px;
    }
  }
  h6 {
    @include font-size(20);
    font-weight: bold;
    margin-bottom: 20px;
    @include SP {
      @include font-size(15);
      margin-bottom: 10px;
    }
  }
  p {
    line-height: 2;
    & + p {
      margin-top: 32px;
    }
    & + h2 {
      margin-top: 72px;
      @include SP {
        margin-top: 42px;
      }
    }
    & + h3 {
      margin-top: 57px;
      @include SP {
        margin-top: 42px;
      }
    }
    & + ul,
    & + ol {
      margin-top: 21px;
      @include SP {
        margin-top: 29px;
      }
    }
  }
}

/* WordPress */
.wp-pagenavi {
  text-align: center;
  font-size: 0;
  position: relative;
  @include SP {
    padding: 0 14%;
    min-height: 60px;
  }
  a,
  span {
    @include ffJose;
    font-weight: 400;
    border: none;
    color: $color_body;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
    padding: 0;
    line-height: 1.35;
    @include letter-spacing(80);
  }
  .current {
    color: #ed5118;
    font-weight: normal;
    opacity: 1;
    border-bottom: 4px solid #ed5118;
  }
  .page,
  .current {
    @include font-size(28);
    min-width: 16px;
  }
  .extend {
    @include font-size(28);
    margin: 0 8px;
    padding: 0;
  }
  .pages {
    display: none;
  }
  .previouspostslink,
  .nextpostslink {
    position: relative;
    @include font-size(20);
    line-height: 1.35;
    letter-spacing: 0;
    padding-top: 12px;
    &:before {
      position: absolute;
      content: "";
      top: 7px;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      @include IE {
        width: 29px;
        height: 29px;
      }
      @supports (-ms-ime-align: auto) {
        width: 29px;
        height: 29px;
      }
    }
    @include SP {
      position: absolute;
      top: 0;
      @include font-size(16);
      &:before {
        top: 4px;
      }
    }
  }
  .previouspostslink {
    padding-left: 44px;
    margin: 0 76px 0 0;
    &:before {
      left: 0;
      background-image: url($img_url + "img/common/icon/ico_prev.svg");
      @include IE {
        left: 1px;
      }
    }
    @include SP {
      margin: 0;
      padding: 40px 0 0;
      left: 0;
    }
  }
  .nextpostslink {
    padding-right: 44px;
    margin: 0 0 0 76px;
    &:before {
      right: 0;
      background-image: url($img_url + "img/common/icon/ico_next.svg");
      @include IE {
        right: 1px;
      }
    }
    @include SP {
      margin: 0;
      padding: 40px 0 0;
      right: 0;
    }
  }
}
