@charset 'UTF-8';

footer {
    position: relative;

    .wcm {
        @include SP {
            padding: 0;
        }
    }

    .footer-inner {
        background: $grey02;
        padding: 68px 0 50px;

        @include SP {
            padding: 35px 0 25px;
        }
    }
}

.footer-box {
    @include flexbox;
    @include flex-wrap(wrap);
    color: $white;
    margin-bottom: 50px;

    @include SP {
        @include flex-direction(column);
    }
}

.f-left {
    width: 185px;
    position: relative;

    @include max-screen(950px) {
        width: 150px;
    }

    @include SP {
        width: 100%;
        order: 2;
        text-align: center;
        margin-top: 30px;
        @include flexbox;
        @include flex-direction(column);
    }

    &__logo {
        width: 183px;
        margin-bottom: 28px;

        @include max-screen(950px) {
            width: 150px;
        }

        @include SP {
            margin: 30px auto 15px;
            order: 2;
        }
    }

    &__social {
        position: absolute;
        bottom: -80px;
        width: 100%;
        max-width: 112px;
        left: 0;

        @include SP {
            position: unset;
            max-width: 150px;
            margin: 0 auto;
            order: 1;
        }

        ul {
            @include flexbox;
            @include justify-content(space-between);
        }

        a {
            display: block;

            @include HOVER {
                &:hover {
                    opacity: 0.65;
                }
            }
        }

        .facebook {
            width: 12px;
        }

        .twitter {
            width: 23px;
        }

        .youtube {
            width: 30px;
        }
    }

    p {
        @include font(13, 24, 20, 500);

        @include SP {
            order: 2;
        }
    }
}

.f-right {
    width: calc(100% - 185px);
    margin-top: -6px;
    padding-left: 58px;
    @include flexbox;
    @include flex-wrap(wrap);

    @include max-screen(950px) {
        width: calc(100% - 150px);
        padding-left: 25px;
    }

    @include SP {
        width: 100%;
        display: block;
        order: 1;
        margin-top: 0;
        padding-left: 0;
    }

    .no-active {
        pointer-events: none;
        opacity: 0.4;
    }

    ul {
        @include max-screen(950px) {
            flex: 1;
        }

        &>li {
            margin-bottom: 14px;

            @include SP {
                margin-bottom: 0;
                border-bottom: 1px solid #6c6c6c;
            }

            &>a {
                color: $white;
                @include font(15, 23, 60, 600);

                @include max-screen(1140px) {
                    @include font(13);
                }

                @include HOVER {
                    &:hover {
                        opacity: 0.65;
                    }
                }

                @include SP {
                    display: block;
                    @include font-size(15);
                    padding: 15px;
                    @include letter-spacing(20);
                }
            }
        }

        li.has-sub {
            .f-ttl {
                @include HOVER {
                    &:hover {
                        opacity: 0.65;
                    }
                }

                @include SP {
                    position: relative;
                    padding: 0 15px 0 0;

                    span {
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        display: block;
                        right: 19px;
                        top: 20px;

                        &:before,
                        &:after {
                            content: "";
                            width: 14px;
                            height: 1px;
                            background: $white;
                            position: absolute;
                        }

                        &:before {
                            right: 0;
                            top: 7px;
                        }

                        &:after {
                            right: 0;
                            top: 7px;
                            @include transform(rotate(90deg));
                        }

                        &.is-active {
                            &:after {
                                opacity: 0;
                            }
                        }
                    }
                }

                &.no-hover {
                    pointer-events: none;

                    @include SP {
                        pointer-events: unset;
                    }
                }

                a {
                    color: $white;
                    @include font(15, 24, 20, 600);
                    display: block;

                    @include SP {
                        width: 85%;
                        padding: 15px 0 15px 15px;
                    }
                }
            }
        }

        .f-sub {
            margin-top: 3px;

            @include SP {
                margin-top: -5px;
                padding: 0 30px 15px;
                display: none;
            }
        }

        .f-sub li {
            margin-bottom: 5px;

            @include SP {
                margin-bottom: 0;
                border-bottom: none;
            }
        }

        .f-sub a {
            @include font(13, 24, 60, 500);
            padding-left: 8px;
            display: block;
            position: relative;

            &:before {
                content: "";
                width: 4px;
                height: 1px;
                background: $white;
                position: absolute;
                top: 12px;
                left: 0;
            }

            @include max-screen(1140px) {
                @include font(11);
            }

            @include SP {
                padding: 0 0 0 10px;
                @include font(15);
            }
        }
    }
}

.col2 {
    padding-left: 58px;

    @include max-screen(1140px) {
        padding-left: 5%;
    }

    @include max-screen(950px) {
        padding-left: 2.5%;
    }

    @include SP {
        padding-left: 0;
    }

    li.has-sub {
        margin-top: 2px;

        .f-sub li {
            margin-bottom: 2px;
        }
    }
}

.col3 {
    padding-left: 80px;

    @include max-screen(1140px) {
        padding-left: 5%;
    }

    @include max-screen(950px) {
        padding-left: 2.5%;
    }

    @include SP {
        padding-left: 0;
    }

    li.has-sub {
        .f-sub li {
            margin-bottom: 3px;
        }
    }
}

.col4 {
    padding-left: 90px;

    @include max-screen(1140px) {
        padding-left: 5%;
    }

    @include max-screen(950px) {
        padding-left: 2.5%;
    }

    @include SP {
        padding-left: 0;
    }

    li {
        margin-bottom: 11px;
    }
}

.footer-link {
    width: 62%;
    margin-left: auto;
    margin-right: -50px;
    @include flexbox;
    @include justify-content(space-between);

    @include max-screen(1140px) {
        margin-right: 0;
    }

    @include max-screen(950px) {
        width: 70%;
    }

    @include max-screen(800px) {
        width: 75%;
    }

    @include SP {
        width: 100%;
        margin: 0;
        display: block;
    }

    .txt-copyright {
        color: $white;
        @include font(12, 32, 80);
        @include ffJose;

        @include SP {
            text-align: center;
        }
    }

    .privacy-link {
        @include flexbox;

        @include SP {
            display: none;
        }

        li {
            margin: 0 10px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        a {
            @include font(13, 24, 60);
            color: $white;
            text-decoration: underline;

            @include HOVER {
                &:hover {
                    opacity: 0.65;
                }
            }

            @include max-screen(1140px) {
                @include font(11);
            }
        }
    }
}

.gototop {
    z-index: 999;
    position: fixed;
    width: 50px;
    right: 15px;
    bottom: 23px;

    @include SP {
        right: 18px;
    }

    a {
        @include HOVER {
            &:hover {
                opacity: 0.65;
            }
        }
    }

    &.sticky {
        position: absolute;
        top: -75px;
        bottom: unset;

        @include SP {
            top: -60px;
        }
    }

    img {
        @include SP {
            width: 40px;
        }
    }
}